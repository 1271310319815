<template>
  <div class="has-nav-bar">
    <van-nav-bar
      title="进出记录"
      fixed
      left-arrow
      @click-left="$router.back()"
    />
    <div>
      <div class="operate-box">
        <div class="title-box">
          <span class="title">人员</span>
          <span v-if="match === '1'" class="add-box" @click="personRecordClick">
            <van-icon
              class="add-icon"
              name="tianjiagongzuobuzhou"
              class-prefix="iconfont"
              size="16px"
              color="#398cff"
            />人员进出记录
          </span>
        </div>
        <div class="module">
          <ul>
            <li
              v-for="item of workerRecordList"
              :key="item.workerId"
              class="item"
            >
              <span class="label">{{ item.workerName }}</span>
              <span class="status" :class="{ enter: !item.enterType }">
                {{ item.enterType ? "出" : "进" }}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div class="operate-box">
        <div class="title-box">
          <span class="title">工器具</span>
          <span v-if="match === '1'" class="add-box" @click="toolRecordClick">
            <van-icon
              class="add-icon"
              name="tianjiagongzuobuzhou"
              class-prefix="iconfont"
              size="16px"
              color="#398cff"
            />工器具进出记录
          </span>
        </div>
        <div class="module">
          <ul>
            <li v-for="(item, k) of toolRecordList" :key="k" class="item">
              <span class="label">{{
                `${item.toolName}(${item.outNum}/${item.enterNum})`
              }}</span>
              <span class="status" :class="{ enter: !item.enterType }">
                {{ item.enterType ? "出" : "进" }}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div class="record">
        <p class="title">进出记录</p>
        <ul>
          <li v-for="(item, k) of detailRecordList" :key="k" class="item">
            <div class="operate">
              <span class="date">
                <span>{{ item.ctime }}</span>
                <span style="margin: 0 4px 0;">{{
                  item.workerGuarderName
                }}</span>
              </span>
              <span class="status" :class="{ enter: !item.enterType }">
                {{ item.enterType ? "退出" : "进入" }}
              </span>
            </div>
            <div class="content">
              <div class="left">
                <span class="type">
                  {{ item.recordType ? "工器具" : "人员" }}
                </span>
              </div>
              <div class="right">
                <template v-if="item.recordType">
                  <span class="name-icon-box">
                    <span class="name">
                      {{ `${item.toolName}(${item.toolNum})` }}
                    </span>
                  </span>
                </template>
                <template v-else>
                  <span
                    class="name-icon-box"
                    @click="showSignature(item.fileId)"
                  >
                    <span class="name">{{ item.workerName }}</span>
                    <i class="iconfont iconfont-icoqianming qianming"></i>
                  </span>
                </template>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <van-dialog
      v-model="dialogVb"
      :title="dialogTitle"
      show-cancel-button
      :before-close="closeHandle"
    >
      <div class="dl-content">
        <div>
          <span class="label">记录：</span>
          <van-radio-group
            v-model="form.enterType"
            direction="horizontal"
            class="radio-group"
            @change="radioChange"
          >
            <van-radio :name="0" icon-size="18px">进入</van-radio>
            <van-radio :name="1" icon-size="18px">退出</van-radio>
          </van-radio-group>
        </div>
        <template v-if="form.recordType">
          <div class="tool-box">
            <ul>
              <li
                v-for="(item, k) in form.detailRecordList"
                :key="k"
                class="tool-item"
              >
                <div style="margin: 0 0 16px;">
                  <span class="row-label">名称：</span>
                  <div class="input-box" @click="toolNameClick(k)">
                    <span>{{ item.toolName }}</span>
                    <!-- <input v-model="item.toolId" type="hidden" /> -->
                  </div>
                </div>
                <div>
                  <span class="row-label">数量：</span>
                  <div class="num-box">
                    <!-- <span class="minus span-block" @click="toolNumMinus(item)">-</span> -->
                    <!-- <input class="span-block num-block" type="text" v-model="item.toolNum" /> -->
                    <!-- <span class="span-block num-block">{{ item.toolNum }}12</span> -->
                    <!-- <van-field
                      v-model="item.toolNum"
                      class="span-block num-block"
                      type="digit"
                    /> -->
                    <!-- <span class="plus span-block">+</span> -->

                    <!-- min="1"
                    :max="form.enterType ? item.maxUseNum : ''" -->
                    <van-stepper
                      v-model="item.toolNum"
                      step="1"
                      integer
                      min="-9999999"
                      max="9999999"
                      input-width="60px"
                      button-size="30px"
                    />
                  </div>
                </div>
              </li>
              <li>
                <span class="add-box" @click="addToolItemClick">
                  <van-icon
                    class="add-icon"
                    name="tianjiagongzuobuzhou"
                    class-prefix="iconfont"
                    size="16px"
                    color="#398cff"
                  />添加工器具
                </span>
              </li>
            </ul>
          </div>
        </template>
        <template v-else>
          <div class="person-signature-box">
            <div>
              <span class="label">签名：</span>
              <van-button class="btn-select-person" round @click="choosePerson"
                >选择人员</van-button
              >
            </div>
            <div class="tag-box">
              <Tag
                v-for="item in form.detailRecordList"
                :key="item.workerId"
                :type="item.fileId ? 'green' : 'white'"
                :tick="!!item.fileId"
                @click.native="
                  handleClickTag(
                    item,
                    item.fileId,
                    item.workerId,
                    item.workerType,
                    ticketId,
                    specialTicketId
                  )
                "
              >
                {{ item.workerName }}
              </Tag>
            </div>
          </div>
        </template>
      </div>
    </van-dialog>
    <WorkticketPersonPicker
      ref="personPicker"
      v-model="form.detailRecordList"
      title="作业人"
      :multiple="true"
      :is-check-person="true"
      :ticket-id="ticketId"
      :special-id="specialTicketId"
      :start-work-space-worker="true"
      :checked-worker="!!form.enterType"
      @change="checkPersonChange"
    />
    <SelectPopupGrade
      v-model="toolSltCurId"
      :immediately="false"
      list-key="toolId"
      :props="{
        label: 'toolName',
        value: 'toolId'
      }"
      title="工器具"
      :list.sync="toolSltDataSwitch"
      :visible="toolSltVb"
      :allow-create="!form.enterType"
      @change="popupGradeChange"
      @close="toolSltVb = false"
    />
  </div>
</template>

<script>
import esign from "@/components/esign";
import { fileUrl } from "@/utils/constant";
import WorkticketPersonPicker from "@/components/picker/WorkticketPersonPicker";
import Tag from "@/components/Tag";
import {
  getSpaceWorkRecord,
  getWorkToolByPage,
  getEnterWorkTool,
  addSpaceWorkRecord
} from "@/api/workticket";
import { deepClone } from "@/utils";

export default {
  name: "DiscrepancyRecord",
  components: {
    WorkticketPersonPicker,
    Tag
  },
  data() {
    return {
      match: this.$route.params.match,
      ticketId: this.$route.params.ticketId,
      specialTicketId: this.$route.params.specialTicketId,
      detailRecordList: [],
      toolRecordList: [],
      workerRecordList: [],
      dialogVb: false,
      form: {
        ticketId: "",
        specialTicketId: "",
        detailRecordList: [
          // {
          //   toolId: "", // 工器具id （recordType为1必填 id和name必须有一个）
          //   toolName: "",
          //   toolNum: 1,
          //   maxUseNum: "",
          //   fileId: "",
          //   workerId: "",
          //   workerType: ""
          // }
        ],
        recordType: 0, // 数据类型 0：人员操作记录，1：工器具操作记录
        enterType: 0 // 操作类型  0: 进入，1:退出
      },
      toolSltData: [],
      dialogTitle: "人员进出记录",
      toolSltVb: false,
      toolSltCurId: "",
      curOperateRowIdx: 0
    };
  },
  computed: {
    toolSltDataSwitch() {
      const list = this.form.detailRecordList;
      return this.toolSltData.filter(i => {
        return !list.find(
          f => f.toolId === i.toolId && this.toolSltCurId !== i.toolId
        );
      });
    }
  },
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getSpaceWorkRecord();
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  methods: {
    async getSpaceWorkRecord() {
      const params = {
        ticketId: this.ticketId,
        specialTicketId: this.specialTicketId
      };
      const res = await getSpaceWorkRecord(params);
      this.detailRecordList = res.detailRecordList || [];
      this.toolRecordList = res.toolRecordList || [];
      this.workerRecordList = res.workerRecordList || [];
    },
    showSignature(fileId) {
      esign.showImage(fileUrl + fileId);
    },
    personRecordClick() {
      this.form = {
        ticketId: this.ticketId,
        specialTicketId: this.specialTicketId,
        detailRecordList: [
          // {
          //   toolId: "", // 工器具id （recordType为1必填 id和name必须有一个）
          //   toolName: "",
          //   toolNum: 1,
          //   maxUseNum: "",
          //   fileId: "",
          //   workerId: "",
          //   workerType: ""
          // }
        ],
        recordType: 0, // 数据类型 0：人员操作记录，1：工器具操作记录
        enterType: 0 // 操作类型  0: 进入，1:退出
      };
      this.dialogTitle = "人员进出记录";
      this.dialogVb = true;
    },
    async toolRecordClick() {
      this.form = {
        ticketId: this.ticketId,
        specialTicketId: this.specialTicketId,
        detailRecordList: [
          {
            toolId: "", // 工器具id （recordType为1必填 id和name必须有一个）
            toolName: "",
            toolNum: 1,
            maxUseNum: "",
            fileId: "",
            workerId: "",
            workerType: ""
          }
        ],
        recordType: 1, // 数据类型 0：人员操作记录，1：工器具操作记录
        enterType: 0 // 操作类型  0: 进入，1:退出
      };
      this.dialogTitle = "工器具进出记录";
      await this.getWorkToolByPage();
      this.dialogVb = true;
    },
    async getWorkToolByPage() {
      const params = {
        page: 1,
        size: 9999
      };
      const res = await getWorkToolByPage(params);
      this.toolSltData = (res?.list || []).map(i => {
        i.toolId = i.id;
        return i;
      });
    },
    async getEnterWorkTool() {
      const params = {
        ticketId: this.ticketId,
        specialTicketId: this.specialTicketId
      };
      this.toolSltData = await getEnterWorkTool(params);
    },
    addToolItemClick() {
      this.form.detailRecordList.push({
        toolId: "", // 工器具id （recordType为1必填 id和name必须有一个）
        toolName: "",
        toolNum: 1,
        maxUseNum: "",
        fileId: "",
        workerId: "",
        workerType: ""
      });
    },
    toolNameClick(idx) {
      this.curOperateRowIdx = idx;
      this.toolSltCurId = this.form.detailRecordList[idx].toolId;
      this.toolSltVb = true;
    },
    popupGradeChange(v) {
      const label = v[0]?.label;
      const value = v[0]?.value;
      const idx = this.curOperateRowIdx;
      this.form.detailRecordList[idx].toolName = v[0]?.toolName || label || "";
      this.form.detailRecordList[idx].toolId = v[0]?.toolId || value || "";
      this.form.detailRecordList[idx].maxUseNum = v[0]?.maxUseNum || "";
      this.form.detailRecordList[idx].toolNum = 1;
      if (v[0]?.allowCreate) {
        if (
          !this.toolSltData.find(i => i.toolId === value && i.toolId === label)
        ) {
          this.toolSltData.push({
            toolId: value,
            toolName: label
          });
        }
      }
    },
    // toolNumMinus(item) {
    //   item.toolNum -= item.toolNum;
    // },
    choosePerson() {
      this.$refs.personPicker.show();
    },
    handleClickTag(item, fileId, personId, personType, ticketId, specialId) {
      if (fileId) {
        //已上传过，直接预览
        esign.showImage(fileUrl + fileId);
        return;
      }
      //
      esign.show(imageRes => {
        fileId = imageRes.id;
        item.fileId = fileId;
        // this.$toast.success("提交成功");
        // addWorkSignature({
        //   fileId,
        //   personId,
        //   personType,
        //   ticketId,
        //   specialId
        // })
        //   .then(res => {
        //     item.fileId = fileId;
        //     this.$toast.success("提交成功");
        //   })
        //   .catch(error => {
        //     this.$toast.fail("签名失败，请重试");
        //   });
      });
    },
    async closeHandle(act, done) {
      if (act === "cancel") {
        done();
        return;
      }
      const list = this.form.detailRecordList;
      if (this.form.recordType) {
        const tool = list.some(i => !i.toolName);
        if (!list.length || tool) {
          done(false);
          return this.$toast.fail("请选择工器具");
        }
        for (let i = 0; i < list.length; i++) {
          const item = list[i];
          if (
            item.toolNum <= 0 ||
            (item.maxUseNum && item.toolNum > item.maxUseNum)
          ) {
            done(false);
            return this.$toast.fail("工器具数量不能小于0,且小于最大数量限制");
          }
        }
      } else {
        if (!list.length) {
          done(false);
          return this.$toast.fail("请选择人员");
        }
        if (list.some(i => !i.fileId)) {
          done(false);
          return this.$toast.fail("请签名");
        }
      }
      try {
        const params = deepClone(this.form);
        params.detailRecordList.forEach(i => {
          if (i.toolId === i.toolName) {
            i.toolId = "";
          }
        });
        await addSpaceWorkRecord(params);
        this.getSpaceWorkRecord();
        done();
        this.$toast.success("提交成功");
      } catch (e) {
        done(false);
        this.$toast.fail("提交失败");
      }
    },
    // async recordConfirm() {
    //   try {
    //     await addSpaceWorkRecord(this.form);
    //     this.getSpaceWorkRecord();
    //     this.$toast.success("提交成功");
    //   } catch (e) {
    //     this.$toast.fail("提交失败");
    //   }
    // },
    async radioChange(name) {
      this.toolSltCurId = "";
      if (this.form.recordType) {
        this.form.detailRecordList = [
          {
            toolId: "", // 工器具id （recordType为1必填 id和name必须有一个）
            toolName: "",
            toolNum: 1,
            maxUseNum: "",
            fileId: "",
            workerId: "",
            workerType: ""
          }
        ];
        if (name) {
          await this.getEnterWorkTool();
        } else {
          await this.getWorkToolByPage();
        }
      } else {
        this.form.detailRecordList = [];
      }
    },
    checkPersonChange(val) {
      const data = val ? (Array.isArray(val) ? val : [val]) : [];
      if (!data.length) {
        this.form.detailRecordList = [];
        return;
      }
      const checkPersonList = [];
      data.forEach(item => {
        const find = this.form.detailRecordList.find(
          f => f.personId === item.value
        );
        let obj = { ...find };
        if (!find) {
          obj = {
            fileId: "",
            personType: item.personType,
            workerType: item.personType,
            workerId: item.value,
            workerName: item.label
          };
        }
        checkPersonList.push(obj);
      });
      this.form.detailRecordList = checkPersonList;
    }
  }
};
</script>

<style lang="scss" scoped>
.operate-box {
  padding: 13px 16px 20px;
  background-color: #fff;
  border-bottom: 8px solid #eceef2;
  .title-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-size: 16px;
      font-weight: 500;
      color: #2e2e4d;
      line-height: 22px;
    }
    .add-box {
      font-size: 12px;
      font-weight: 400;
      color: #2e2e4d;
      line-height: 17px;
      .add-icon {
        margin: 0 2px 0 0;
        vertical-align: middle;
      }
    }
  }
  .module {
    padding: 20px 0 0;
    .item {
      display: inline-block;
      margin: 0 20px 12px 0;
      .label {
        font-size: 14px;
        font-weight: 400;
        color: #15171b;
        line-height: 14px;
        margin: 0 4px 0 0;
        vertical-align: middle;
      }
      .status {
        display: inline-block;
        width: 20px;
        height: 20px;
        background-color: #a0a2a6;
        border-radius: 4px;
        text-align: center;
        line-height: 20px;
        // padding: 4px;
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
        vertical-align: middle;
      }
      .status.enter {
        background-color: #fb7709;
      }
    }
  }
}
.record {
  padding: 13px 16px 20px;
  .title {
    font-size: 16px;
    font-weight: 500;
    color: #2e2e4d;
    line-height: 22px;
    margin: 0 0 4px;
  }
  .item {
    padding: 12px 0;
    border-bottom: 1px solid #e1e3e8;
    .operate {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .date {
        font-size: 12px;
        font-weight: 400;
        color: #a0a2a6;
      }
      .status {
        font-size: 12px;
        font-weight: 400;
        color: #a0a2a6;
      }
      .status.enter {
        color: #fb7709;
      }
    }
    .content {
      display: flex;
      margin: 15px 0 0;
      align-items: center;
      .left {
        margin: 0 16px 0 0;
        .type {
          display: inline-block;
          width: 48px;
          height: 20px;
          background-color: #1678ff;
          border-radius: 4px;
          text-align: center;
          line-height: 20px;
          font-size: 12px;
          font-weight: 400;
          color: #ffffff;
        }
      }
      .right {
        .name-icon-box {
          display: inline-block;
          margin: 0 12px 0 0;
          font-size: 14px;
          font-weight: 400;
          color: #5d5f63;
          line-height: 22px;
          .name {
            vertical-align: middle;
          }
          .qianming {
            margin: 0 0 0 4px;
            font-size: 20px;
            color: #2eb752;
            vertical-align: middle;
          }
        }
      }
    }
  }
}
.dl-content {
  padding: 24px 20px 20px;
  .label {
    font-size: 14px;
    font-weight: 400;
    color: #5d5f63;
    margin: 0 20px 0 0;
    line-height: 20px;
    display: inline-block;
    vertical-align: middle;
  }
  .radio-group {
    display: inline-flex;
    vertical-align: middle;
    .van-radio {
      margin: 0 30px 0 0;
      ::v-deep .van-radio__label {
        font-size: 14px;
        font-weight: 400;
        color: #15171b;
        margin-left: 6px;
      }
    }
  }
  .tool-box {
    margin: 20px 0 0;
    .add-box {
      font-size: 12px;
      font-weight: 400;
      color: #2e2e4d;
      line-height: 17px;
      .add-icon {
        margin: 0 2px 0 0;
        vertical-align: middle;
      }
    }
    .tool-item {
      padding: 20px 16px;
      margin: 0 0 12px;
      background-color: #f3f5f8;
      border-radius: 8px;
      .row-label {
        display: inline-block;
        width: 50px;
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #5d5f63;
        line-height: 20px;
        vertical-align: middle;
      }
      .input-box {
        display: inline-block;
        position: relative;
        width: 193px;
        height: 30px;
        box-sizing: border-box;
        padding: 5px 12px;
        background-color: #fff;
        border-radius: 2px;
        border: 1px solid #e1e3e8;
        vertical-align: middle;
        font-size: 14px;
        font-weight: 400;
        color: #15171b;
        line-height: 20px;
      }
      .input-box::after {
        content: " ";
        position: absolute;
        right: 12px;
        top: calc(50% - 3px);
        width: 0px;
        height: 0px;
        border: 6px solid #000;
        border-top-color: #aeb3c0;
        border-bottom-color: transparent;
        border-left-color: transparent;
        border-right-color: transparent;
      }
      .num-box {
        display: inline-flex;
        // width: 120px;
        // height: 30px;
        // line-height: 30px;
        // text-align: center;
        // background-color: #fff;
        // border-radius: 2px;
        // border: 1px solid #e1e3e8;
        // box-sizing: border-box;
        vertical-align: middle;
        .van-stepper {
          border: 1px solid #e1e3e8;
          border-radius: 2px;
          height: auto;
          line-height: initial;
          box-shadow: none;
          ::v-deep button {
            background-color: #f3f5f8;
          }
          ::v-deep .van-stepper__input {
            border: 1px solid #e1e3e8;
            border-top: none;
            border-bottom: none;
          }
        }
        // .span-block {
        //   display: inline-block;
        //   width: 30px;
        //   height: 30px;
        //   background-color: #f3f5f8;
        //   font-size: 29px;
        // }
        // .num-block {
        //   display: inline-block;
        //   width: 60px;
        //   background-color: #fff;
        //   font-size: 14px;
        //   font-weight: 400;
        //   color: #15171b;
        //   // overflow: auto;
        //   border: none;
        //   padding: 0;
        //   text-align: center;
        //   ::v-deep {
        //     .van-cell__value,
        //     .van-field__body {
        //       height: 100%;
        //     }
        //     .van-field__control {
        //       text-align: center;
        //     }
        //   }
        // }
        // .minus {
        //   border-right: 1px solid #e1e3e8;
        // }
        // .plus {
        //   border-left: 1px solid #e1e3e8;
        // }
      }
    }
  }
  .person-signature-box {
    margin: 24px 0 0;
    .btn-select-person {
      display: inline-block;
      width: 86px;
      height: 32px;
      line-height: 30px;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      background-color: #1676ff;
      color: #ffffff;
      padding: 0;
      margin: 0 0 16px;
      vertical-align: super;
    }
  }
}
</style>
